
import React from "react"

import BrandeditorPage from "../../components/negosyonow/controls/brandeditor";


const BrandimageonlyPage = ({location}) => {
	return <BrandeditorPage
				location={location}
				allowadd={false}
			/>
}


export default BrandimageonlyPage;
